// axios
import axios from 'axios';

const baseURL = '';

export default axios.create({
  baseURL,
  headers: { 'Accept-Language': 'de' }
  // You can add your headers here
});
