/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import auth from '@/auth/jwt';
import router from '@/router';

export default {
  // JWT
  loginJWT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      auth
        .login(payload.userDetails.username, payload.userDetails.password)
        .then((response) => {
          // If there's user data in response
          if (response.data.userData) {
            const { role } = response.data.userData;
            if (role.toLowerCase() !== 'admin') {
              reject({ message: 'No authorization to login' });
              return;
            }
            // Set accessToken
            localStorage.setItem('accessToken', response.data.accessToken);

            // Update user details
            commit('UPDATE_USER_INFO', response.data.userData, { root: true });

            // Set bearer token in axios
            commit('SET_BEARER', response.data.accessToken);

            // Set update params
            commit('SET_VALID_UNTIL', response.data.validUntil);
            commit('SET_REFRESH_UNTIL', response.data.refreshUntil);

            // Navigate User to homepage or custom url
            router.push(router.currentRoute.query.to || '/');

            resolve(response);
          } else {
            reject({ message: 'Wrong Username or Password' });
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  registerUserJWT({ commit }, payload) {
    const { displayName, username, password, confirmPassword } = payload.userDetails;

    return new Promise((resolve, reject) => {
      // Check confirm password
      if (password !== confirmPassword) {
        reject({ message: "Password doesn't match. Please try again." });
      }

      auth
        .registerUser(displayName, username, password)
        .then((response) => {
          // Redirect User
          router.push(router.currentRoute.query.to || '/');

          // Update data in localStorage
          localStorage.setItem('accessToken', response.data.accessToken);
          commit('UPDATE_USER_INFO', response.data.userData, { root: true });

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAccessToken({ commit }) {
    return new Promise((resolve, reject) => {
      auth.refreshToken().then((response) => {
        if (response.data.accessToken) {
          // Set accessToken
          localStorage.setItem('accessToken', response.data.accessToken);

          // Set bearer token in axios
          commit('SET_BEARER', response.data.accessToken);

          // Set update params
          commit('SET_VALID_UNTIL', response.data.validUntil);
          commit('SET_REFRESH_UNTIL', response.data.refreshUntil);

          resolve(response.data.accessToken);
        } else {
          reject({ message: 'Token refresh failed' });
        }
      });
    });
  }
};
