import axios from '@/axios.js';

export default {
  namespaced: true,

  state: () => ({
    wageTypesToNationalityDictionary: {}
  }),

  mutations: {
    setWageTypeForNationality(state, { nationalityId, wageType }) {
      const dictionary = { ...state.wageTypesToNationalityDictionary }
      dictionary[nationalityId] = wageType
      state.wageTypesToNationalityDictionary = dictionary
    }
  },

  actions: {
    async fetchWageTypeForNationality({ commit, state }, nationalityId) {
      try {
        if (state.wageTypesToNationalityDictionary[nationalityId]) {
          return state.wageTypesToNationalityDictionary[nationalityId];
        }

        const { data } = await axios.get(
          `/api/payroll-management/wage-types/country/${nationalityId}`
        )

        commit('setWageTypeForNationality', {
          wageType: data,
          nationalityId
        });

        return data

      } catch (error) {
        console.error(error)
        return []
      }
    },
  }
};
