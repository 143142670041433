/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  /**
   * @todo catch error if a key gets deleted by user
   * @returns {boolean}
   */
  isUserLoggedIn: () => {
    let userInfo = localStorage.getItem('userInfo');

    if (userInfo) {
      let validUntil = Number.parseInt(localStorage.getItem('validUntil')) * 1000;
      let refreshUntil = Number.parseInt(localStorage.getItem('refreshUntil')) * 1000;
      let now = Date.now();

      // refresh impossible, token has expired
      if (now > refreshUntil) return false;
      // refresh needed
      else if (now > validUntil) return true;
      // no action
      else return true;
    } else return false;
  }
};
