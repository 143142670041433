/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './src/main.js';

import CssBase from 'css-loader/lib/css-base';
import AddStyles from 'style-loader/lib/addStyles';

{
  CssBase;
  AddStyles;
}
