/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js';

export default {
  SET_BEARER(state, accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  },

  SET_VALID_UNTIL(state, timestamp) {
    localStorage.setItem('validUntil', timestamp);
  },

  SET_REFRESH_UNTIL(state, timestamp) {
    localStorage.setItem('refreshUntil', timestamp);
  }
};
