/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import Router from 'vue-router';
import store from './store/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/admin'
        },
        {
          path: '/admin',
          name: 'admin',
          component: () => import('@/views/admin/Admin.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Admin User
        // =============================================================================
        {
          path: '/admin/users',
          name: 'admin-users',
          component: () => import('./views/admin/users/UserList.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/users/:id/edit',
          name: 'admin-edit-users',
          component: () => import('./views/admin/users/UserEdit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/users/create',
          name: 'admin-create-users',
          component: () => import('./views/admin/users/UserCreate.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Admin Management
        // =============================================================================
        // =============================================================================
        // Employees Management
        // =============================================================================
        {
          path: '/admin/employees',
          name: 'admin-employees',
          component: () => import('./views/admin/management/employees/EmployeeList.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/employees/:id/edit',
          name: 'admin-edit-employees',
          component: () => import('./views/admin/management/employees/EmployeeEdit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/employees/create',
          name: 'admin-create-employees',
          component: () => import('./views/admin/management/employees/EmployeeCreate.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // ConstructionSiteAssignements Management
        // =============================================================================
        {
          path: '/admin/constructionsiteassignments',
          name: 'admin-employee-siteAssignment',
          component: () => import('./views/admin/management/constructionsiteassignments/ConstructionSiteAssignments.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Customers Management
        // =============================================================================
        {
          path: '/admin/customers',
          name: 'admin-customers',
          component: () => import('./views/admin/management/customers/CustomerList.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/customers/:id/edit',
          name: 'admin-edit-customers',
          component: () => import('./views/admin/management/customers/CustomerEdit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/customers/create',
          name: 'admin-create-customers',
          component: () => import('./views/admin/management/customers/CustomerCreate.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // ConstructionSites Management
        // =============================================================================
        {
          path: '/admin/construction-sites',
          name: 'admin-construction-sites',
          component: () => import('./views/admin/management/construction-sites/list.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/construction-sites/create',
          name: 'admin-create-construction-sites',
          component: () => import('./views/admin/management/construction-sites/create.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/construction-sites/:id/edit',
          name: 'admin-edit-construction-sites',
          component: () => import('./views/admin/management/construction-sites/edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Payroll Management
        // =============================================================================
        // =============================================================================
        // Wage Types
        // =============================================================================
        {
          path: '/admin/payroll-management/wage-types',
          name: 'admin-payroll-management-wage-types',
          component: () => import('./views/admin/management/payroll-management/wage-types/list-view/index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/payroll-management/wage-types/:id/edit',
          name: 'admin-edit-payroll-management-wage-types',
          component: () => import('./views/admin/management/payroll-management/wage-types/edit-view/index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/payroll-management/wage-types/create',
          name: 'admin-create-payroll-management-wage-types',
          component: () => import('./views/admin/management/payroll-management/wage-types/create-view/index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Collective Agreements
        // =============================================================================
        {
          path: '/admin/payroll-management/collective-agreements',
          name: 'admin-payroll-management-collective-agreements',
          component: () => import('./views/admin/management/payroll-management/collective-agreements/CollectiveAgreementList.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/payroll-management/collective-agreements/:id/edit',
          name: 'admin-edit-payroll-management-collective-agreements',
          component: () => import('./views/admin/management/payroll-management/collective-agreements/CollectiveAgreementEdit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/payroll-management/collective-agreements/create',
          name: 'admin-create-payroll-management-collective-agreements',
          component: () => import('./views/admin/management/payroll-management/collective-agreements/CollectiveAgreementCreate.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Tariff Rules
        // =============================================================================
        {
          path: '/admin/payroll-management/tariff-rules',
          name: 'admin-payroll-management-tariff-rules-index',
          component: () => import('./views/admin/management/payroll-management/tariff-rules/index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/payroll-management/tariff-rules/:id/edit',
          name: 'admin-payroll-management-tariff-rules-edit',
          component: () => import('./views/admin/management/payroll-management/tariff-rules/edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/payroll-management/tariff-rules/create',
          name: 'admin-payroll-management-tariff-rules-create',
          component: () => import('./views/admin/management/payroll-management/tariff-rules/create.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Working Time and Expenses Recording
        // =============================================================================
        {
          path: '/admin/recording',
          name: 'admin-recording-create',
          component: () => import('./views/admin/actions/recording/admin/create.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/recording/:id',
          name: 'admin-recording-edit',
          component: () => import('./views/admin/actions/recording/admin/edit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/recording-list',
          name: 'admin-recording-list',
          component: () => import('./views/admin/actions/recording/admin/index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/pwa/recording-list',
          name: 'admin-pwa-recording-list',
          component: () => import('./views/admin/actions/recording/pwa/index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },

        // =============================================================================
        // Payroll
        // =============================================================================
        {
          path: '/admin/payroll',
          name: 'admin-payroll',
          component: () => import('./views/admin/actions/payroll/PayrollList.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/payroll/edit',
          name: 'admin-payroll-edit',
          component: () => import('./views/admin/actions/payroll/Payroll.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        // =============================================================================
        // Invoices
        // =============================================================================
        {
          path: '/admin/invoices',
          name: 'invoice-overview',
          component: () => import('./views/admin/actions/invoice/InvoiceList.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/invoices/:id/edit',
          name: 'invoice-edit',
          component: () => import('./views/admin/actions/invoice/InvoiceEdit.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/admin/invoices/:id/pdf',
          name: 'invoice-pdf',
          component: () => import('./views/admin/actions/invoice/pdf/index.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/admin/pwa/recording-list/pdf',
          name: 'admin-pwa-recording-list-pdf',
          component: () => import('./views/admin/actions/recording/pwa/pdf.vue'),
          meta: {
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/public/actions/status-permit/:token',
          name: 'public-actions-status-permit',
          component: () => import('./views/public/actions/statusPermit/index.vue'),
          meta: {
            rule: 'editor',
          }
        },
        {
          path: '/public/invalid-token',
          name: 'public-invalid-token',
          component: () => import('./views/public/error/invalidToken.vue'),
          meta: {
            rule: 'editor',
          }
        },
        {
          path: '/public/expired-token',
          name: 'public-expired-token',
          component: () => import('./views/public/error/expiredToken.vue'),
          meta: {
            rule: 'editor',
          }
        },
        {
          path: '/public/unauthorized',
          name: 'public-unauthorized',
          component: () => import('./views/public/error/unauthorized.vue'),
          meta: {
            rule: 'editor',
          }
        },
        {
          path: '/admin/login',
          name: 'page-login',
          component: () => import('@/views/admin/login/Login.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/admin/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/admin/common/ForgotPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/admin/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/admin/common/ResetPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/admin/lock-screen',
          name: 'page-lock-screen',
          component: () => import('@/views/admin/common/LockScreen.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import('@/views/admin/common/Error404.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/error-500',
          name: 'page-error-500',
          component: () => import('@/views/admin/common/Error500.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/admin/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/admin/common/NotAuthorized.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/admin/common/Maintenance.vue'),
          meta: {
            rule: 'editor'
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404'
    }
  ]
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

router.beforeEach((to, from, next) => {
  // If auth required, check login. If login fails redirect to login page
  if (to.meta.authRequired && !store.state.auth.isUserLoggedIn()) {
    // noinspection JSIgnoredPromiseFromCall
    router.push({ path: '/admin/login', query: { to: to.path } });
  } else if (to.name === 'page-login' && store.state.auth.isUserLoggedIn()) {
    // noinspection JSIgnoredPromiseFromCall
    router.push({ path: '/' });
  }

  return next();
  // Specify the current path as the customState parameter, meaning it
  // will be returned to the application after auth
  // auth.login({ target: to.path });
});

export default router;
