import axios from '@/axios.js';

const clone = (value) => {
  return JSON.parse(JSON.stringify(value));
};

export default {
  namespaced: true,

  state: () => ({
    nationalities: null,
  }),

  mutations: {
    setNationalities(state, nationalities) {
      state.nationalities = nationalities;
    },
  },

  actions: {
    // returns a all nationalities as an array
    // the array is cloned because its passed by reference
    // its to ensure no one mutates the original
    async getNationalities({ commit, state }) {
      try {
        if (state.nationalities !== null) return clone(state.nationalities);

        const { data } = await axios.get('/api/general/nationality');

        data.sort((a, b) => a.name.localeCompare(b.name));

        commit('setNationalities', data);

        return clone(data);
      } catch (error) {
        console.error(error);
        return [];
      }
    },

    async getNationality({ state, dispatch }, requestedNationalityId) {
      let nationalities = null;

      if (!state.nationalities) {
        nationalities = await dispatch('getNationalities');
      } else {
        nationalities = state.nationalities;
      }

      return nationalities.find((x) => x.id === requestedNationalityId);
    },
  },
};
