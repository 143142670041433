import axios from '@/axios.js';
import store from '@/store/store.js';

// Token Refresh
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter((callback) => callback(access_token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

export default {
  init() {
    // set default auth header if access Token is present
    if (localStorage.getItem('accessToken')) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
    }

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            store.dispatch('auth/fetchAccessToken').then((access_token) => {
              isAlreadyFetchingAccessToken = false;
              onAccessTokenFetched(access_token);
            });
          }

          const retryOriginalRequest = new Promise((resolve) => {
            addSubscriber((access_token) => {
              originalRequest.headers.Authorization = `Bearer ${access_token}`;
              resolve(axios(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  },
  login(username, pwd) {
    return axios.post('/api/auth/login', {
      name: username,
      password: pwd
    });
  },
  registerUser(name, username, pwd) {
    return axios.post('/api/auth/register', {
      displayName: name,
      name: username,
      password: pwd
    });
  },
  refreshToken() {
    return axios.get('/api/auth/refresh-token');
    //return axios.post('/api/auth/refresh-token', {accessToken: localStorage.getItem('accessToken')})
  }
};
